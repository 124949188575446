






































import useApi from "@/use/api";
import useUser from "@/use/user";
import { defineComponent, reactive, ref } from "@vue/composition-api";

export default defineComponent({
  components: {
    MCompanyFilter: () => import('@/components/molecules/m-company-filter.vue'),
    MDatePicker: () => import('@/components/molecules/m-date-picker.vue')
  },
  setup(_, { root }) {
    const { hasAccessTo, hasManyCompanies } = useUser({ root });
    const { axiosInstance } = useApi({ root });

    const form = ref()
    const isFormValid = ref()

    const loading = ref(false);
    const error = ref<null | string>(null)

    const model = reactive({
      company: null as any,
      name: ''
    })

    const submit = async () => {
      error.value = null
      loading.value = true

      await form.value?.validate()

      if (isFormValid.value) {
        const formData = {
          company: model.company?.id ?? undefined,
          name: model.name ?? undefined
        }

        axiosInstance
          .post('company-structure', formData)
          .then(() => {
            root.$router.push({ name: 'panel.company-structure.list' })
          })
          .catch(() => {
            error.value = 'Nie można dodać struktury'
          })
          .finally(() => loading.value = false)
      } else {
        loading.value = false
      }
    }

    return { hasAccessTo, hasManyCompanies, form, isFormValid, loading, error, model, submit };
  },
});
